import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import TextArea from "@material-ui/core/TextareaAutosize"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import LinearProgress from "@material-ui/core/LinearProgress"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import ToggleButton from "@material-ui/lab/ToggleButton"
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup"
import Checkbox from "@material-ui/core/Checkbox"
import axios from "axios"
import React, { useRef, useState } from "react"

import "./styles.sass"

const useStyles = makeStyles(theme => ({
  root: {},
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {},
}))

const SUBSCRIPTION_ENDPOINT =
  "https://4sknkfm9g8.execute-api.us-east-2.amazonaws.com/production/beta_users"
// const SUBSCRIPTION_ENDPOINT = "http://localhost:4002/beta_users"
export default function FormDialog() {
  const [open, setOpen] = React.useState(false)
  const [email, setEmail] = React.useState()
  const [fullname, setFullname] = React.useState()
  const [role, setRole] = React.useState()
  const [org, setOrg] = React.useState()
  const [newsletter, setNewsletter] = React.useState(true)
  const [stack, setStack] = React.useState()
  const [error, setError] = React.useState()
  const [success, setSuccess] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [problem, setProblem] = React.useState()
  const classes = useStyles()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleRegister = () => {
    const data = {
      email,
      fullname,
      role,
      org,
      stack,
      problem,
    }

    const allFilled = Object.values(data).some(
      value => typeof value !== "undefined"
    )
    if (!allFilled) {
      alert("All fields are required")
    }
    setLoading(true)
    axios
      .post(
        SUBSCRIPTION_ENDPOINT,
        JSON.stringify({
          ...data,
          newsletter,
        })
      )
      .then(function(response) {
        setSuccess(true)
      })
      .catch(function(error) {
        if (error.errorCode === 400) {
          alert("This email is already in use.")
        } else {
          alert("Something wrong happened. Please retry later.")
        }
      })
      .finally(function() {
        setLoading(false)
      })
  }

  return (
    <div className="beta-form">
      <Button
        className="beta-form-cta"
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
      >
        Get Early Access
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">BETA Registration</DialogTitle>
        {success ? (
          <>
            <DialogContent>
              <DialogContentText>
                Thank you for your interest! We will be in touch soon.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                The private Beta will begin early 2020. <br />
                If you are interested in taking part, please fill out the form
                bellow.
              </DialogContentText>
              <TextField
                margin="dense"
                required
                className={clsx(classes.margin, classes.textField)}
                id="email"
                value={email}
                color="primary"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setEmail(e.target.value)}
                label="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                required
                value={fullname}
                color="primary"
                onChange={e => setFullname(e.target.value)}
                className={clsx(classes.margin, classes.textField)}
                margin="dense"
                id="fullname"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Full name"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                required
                value={role}
                color="primary"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setRole(e.target.value)}
                className={clsx(classes.margin, classes.textField)}
                id="role"
                label="Role"
                type="text"
                fullWidth
              />
              <TextField
                margin="dense"
                required
                color="primary"
                InputLabelProps={{
                  shrink: true,
                }}
                className={clsx(classes.margin, classes.textField)}
                id="organization"
                label="Organization"
                value={org}
                onChange={e => setOrg(e.target.value)}
                type="text"
                fullWidth
              />

              <TextField
                id="stack"
                required
                className={clsx(classes.margin, classes.textField)}
                label="Briefly describe the code stack "
                color="primary"
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Framework, JS/node version.."
                value={stack}
                onChange={e => setStack(e.target.value)}
                type="text"
                fullWidth
              />
              <TextField
                required
                id="problem"
                value={problem}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={e => setProblem(e.target.value)}
                color="primary"
                className={clsx(classes.margin, classes.textField)}
                label="What problem(s) do you wish to solve with CodeCue"
                type="text"
                fullWidth
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="newsletter"
                    value="newsletter"
                    checked={newsletter}
                    color="primary"
                    onChange={e => setNewsletter(!newsletter)}
                  />
                }
                label="I want to receive occasional updates about CodeCue progress"
              />
            </DialogContent>
            <DialogActions>
              <Button disabled={loading} onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                disabled={loading}
                onClick={handleRegister}
                color="primary"
              >
                Register
              </Button>
            </DialogActions>
            {loading && <LinearProgress color="secondary" />}
          </>
        )}
      </Dialog>
    </div>
  )
}
