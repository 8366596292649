import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import "./why.sass"
const Why = () => {
  const data = useStaticQuery(
    graphql`
      query {
        peopleIcon: file(relativePath: { eq: "plus-people-icon.png" }) {
          childImageSharp {
            fixed(width: 75, height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        ghConnectIcon: file(relativePath: { eq: "gh-connect-icon.png" }) {
          childImageSharp {
            fixed(width: 75, height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        improveIcon: file(relativePath: { eq: "improve-icon.png" }) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        moduleIcon: file(relativePath: { eq: "module-icon.png" }) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        patternIcon: file(relativePath: { eq: "pattern-icon.png" }) {
          childImageSharp {
            fixed(width: 65, height: 65) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        timeIcon: file(relativePath: { eq: "time-icon.png" }) {
          childImageSharp {
            fixed(width: 75, height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        questionsIcon: file(relativePath: { eq: "questions-icon.png" }) {
          childImageSharp {
            fixed(width: 75, height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <div className="header-why">
      <div className="why-card">
        <span className="why-smallIcon">
          <Img fixed={data.patternIcon.childImageSharp.fixed} />
        </span>
        <h4>Codebase-Wide Lookups</h4>
        <p>
          CodeCue indexes all the functions name so that you can find functions,
          classes or code snippets throughout the whole code base in a matter of
          seconds.
        </p>
      </div>
      <div className="why-card">
        <span className="why-smallIcon">
          <Img fixed={data.improveIcon.childImageSharp.fixed} />
        </span>
        <h4>IDE Integration</h4>
        <p>
          No need to learn or integrate a new tool, CodeCue search engine can be
          used right inside your IDE. (Currently only supports VSCode but more
          integrations on the way)
        </p>
      </div>
      <div className="why-card">
        <span className="why-smallIcon">
          <Img fixed={data.moduleIcon.childImageSharp.fixed} />
        </span>
        <h4>Semantic Search Engine</h4>
        <p>
          With semantic matching there is no need to know an exact function name
          to find it. Synonyms will work just as well. <br />
          e.g.: <b>userSettings</b> ~ <b>configureUser</b>
        </p>
      </div>
    </div>
  )
}
export default Why
