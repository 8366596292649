import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import "./how.sass"
const How = () => {
  const data = useStaticQuery(
    graphql`
      query {
        peopleIcon: file(relativePath: { eq: "plus-people-icon.png" }) {
          childImageSharp {
            fixed(width: 75, height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        GHConnectIcon: file(relativePath: { eq: "gh-connect-icon.png" }) {
          childImageSharp {
            fixed(width: 75, height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        timeIcon: file(relativePath: { eq: "time-icon.png" }) {
          childImageSharp {
            fixed(width: 75, height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        questionsIcon: file(relativePath: { eq: "questions-icon.png" }) {
          childImageSharp {
            fixed(width: 75, height: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  return (
    <div className="how-this">
      <h3>How does this work</h3>
      <div className="how-this-list">
        <div className="how-this-item">
          <span className="how-this-icon">
            <Img fixed={data.peopleIcon.childImageSharp.fixed} />
          </span>
          <h5>
            <span className="how-this-bullet-count">1</span>Create your account
          </h5>
          <p>
            We are currently in close Beta test but we let people in everyday.
            Get on the waiting list <a>here</a>
          </p>
        </div>
        <div className="how-this-item">
          <span className="how-this-icon">
            <Img fixed={data.GHConnectIcon.childImageSharp.fixed} />
          </span>
          <h5>
            <span className="how-this-bullet-count">2</span>Connect to your
            Github account
          </h5>
          <p>
            Select the repository and branch that you want to be indexed. Note:
            We currently only support Github.
          </p>
        </div>
        <div className="how-this-item">
          <span className="how-this-icon">
            <Img fixed={data.timeIcon.childImageSharp.fixed} />
          </span>
          <h5>
            <span className="how-this-bullet-count">3</span>Hang on a few
            minutes
          </h5>
          <p>
            First, CodeCue is extracting semantic information from the source code then fine
            tunes a machine learning model. This process can take a few minutes
            to a few hours depending on the size of your repo.
          </p>
        </div>
        <div className="how-this-item">
          <span className="how-this-icon">
            <Img fixed={data.questionsIcon.childImageSharp.fixed} />
          </span>
          <h5>
            <span className="how-this-bullet-count">4</span>CodeCue is ready to
            answer questions
          </h5>
          <p>
            Login the web interface, ask your questions using natural language
            and get immediate answers.
          </p>
        </div>
      </div>
    </div>
  )
}
export default How
