import React from "react"
import "./logo.sass"

const Logo = () => {
  return (
    <svg
      className="logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 692.71 215.34"
    >
      <title>CodeCue</title>
      <path
        d="M771,618.81a8,8,0,0,0-12-6.91l-91.16,52.15a19.37,19.37,0,0,0-9.69,16.78l1.14,102a8,8,0,0,0,12,6.91l94.58-54.13a12.53,12.53,0,0,0,6.27-10.86Zm-94.92,64.25a6.58,6.58,0,0,1,3.28-5.68l45.47-26.33a3.64,3.64,0,0,1,5.46,3.15v6.06a5.79,5.79,0,0,1-2.89,5l-45.86,26.56a3.64,3.64,0,0,1-5.46-3.15Zm59.35-12.93a3.64,3.64,0,0,1,5.47,3.15v6.06a5.79,5.79,0,0,1-2.89,5L709.28,701.3a3.64,3.64,0,0,1-5.46-3.15v-5.61a6.57,6.57,0,0,1,3.28-5.68ZM697.25,697v6.06a5.79,5.79,0,0,1-2.89,5l-12.76,7.33a3.64,3.64,0,0,1-5.46-3.15v-5.61a6.57,6.57,0,0,1,3.28-5.68l12.37-7.11a3.64,3.64,0,0,1,5.46,3.16Zm-21.13,36a5.86,5.86,0,0,1,2.92-5.06l3.51-2a4.16,4.16,0,0,1,6.25,3.61v5.08a4.14,4.14,0,0,1-2,3.54l-4.35,2.68a4.16,4.16,0,0,1-6.35-3.54Zm76.64-8.59a5.79,5.79,0,0,1-2.89,5l-68.29,39.37h0a3.64,3.64,0,0,1-5.46-3.16V760a6.58,6.58,0,0,1,3.28-5.68l67.9-39.14a3.64,3.64,0,0,1,5.46,3.15Zm2.06-27a5.79,5.79,0,0,1-2.89,5L698.6,733.28a3.64,3.64,0,0,1-5.46-3.15v-5.61a6.57,6.57,0,0,1,3.28-5.68l52.95-30.6a3.64,3.64,0,0,1,5.46,3.15Zm1.27-52a5.79,5.79,0,0,1-2.89,5l-12.76,7.33a3.64,3.64,0,0,1-5.46-3.15V649a6.58,6.58,0,0,1,3.28-5.68l12.37-7.11a3.64,3.64,0,0,1,5.46,3.16Z"
        transform="translate(-594.39 -575.51)"
        fill="#fff"
      />
      <path
        d="M658.32,660.12l66.4-38-.21-19.33a7,7,0,0,0-10.5-6.06l-80,45.77a17,17,0,0,0-8.5,14.72l1,89.53a7,7,0,0,0,10.5,6.06L650.61,745l-.78-70.2A17,17,0,0,1,658.32,660.12Z"
        transform="translate(-594.39 -575.51)"
        fill="#fff"
        opacity="0.7"
      />
      <path
        d="M626.49,638.45l65-37.17-.2-18.91A6.85,6.85,0,0,0,681,576.44l-78.28,44.78a16.63,16.63,0,0,0-8.32,14.41l1,87.61a6.85,6.85,0,0,0,10.27,5.93l13.3-7.61-.77-68.7A16.63,16.63,0,0,1,626.49,638.45Z"
        transform="translate(-594.39 -575.51)"
        fill="#fff"
        opacity="0.5"
      />
      <path
        d="M894,692.77c-4.7,11.17-17.35,20.78-33.32,20.78-21.46,0-37.83-16.56-37.83-37.54,0-20.68,16-37.54,37.73-37.54A36.86,36.86,0,0,1,894,659.64h-8.72a29.1,29.1,0,0,0-24.7-14c-17,0-29.7,13.43-29.7,30.28,0,17.15,12.84,30.48,29.7,30.48,15.19,0,22.93-10.88,24.7-13.62Z"
        transform="translate(-594.39 -575.51)"
        fill="#fff"
      />
      <path
        d="M901.23,685.81c0-14.7,11-27.74,27.93-27.74s28,13,28,27.83-11,27.74-28,27.74-27.93-12.94-27.93-27.74Zm7.15.2a20.53,20.53,0,0,0,20.78,21c11.76,0,20.78-9,20.78-21.07s-9-21.07-20.78-21.07-20.78,9-20.78,21.07Z"
        transform="translate(-594.39 -575.51)"
        fill="#fff"
      />
      <path
        d="M1020.8,712.27h-7V701.39c-3.72,7-10.78,12.35-21.76,12.35-16.86,0-27.25-13.13-27.25-27.93,0-15.68,11.37-27.74,27.15-27.74,11.56,0,19.21,6.66,21.56,12.15V639.74h7.25ZM972,685.91c.1,10.58,7.45,21.07,21.17,21.07a20.93,20.93,0,0,0,15-6.17,20.68,20.68,0,0,0,5.88-15.19,20.23,20.23,0,0,0-6.08-14.8,21.14,21.14,0,0,0-36,15Z"
        transform="translate(-594.39 -575.51)"
        fill="#fff"
      />
      <path
        d="M1038.33,688c.39,13.23,11.66,19.6,20.58,19.5,8.13,0,14.7-4,18.13-10.88h7.55a27.94,27.94,0,0,1-10.68,12.84,27.59,27.59,0,0,1-15,4.31c-18.33,0-27.83-14.6-27.83-27.74a27.7,27.7,0,1,1,55.28,2Zm40.87-5.78c-.49-9.21-9.41-18.23-20.29-17.94-11.37,0-19.8,8.33-20.48,17.94Z"
        transform="translate(-594.39 -575.51)"
        fill="#fff"
      />
      <path
        d="M1162.9,696.49c-4.9,8.33-16.47,17.05-31.66,17.05-20.78,0-37.63-15.78-37.63-37.54s16.86-37.54,37.63-37.54A38.26,38.26,0,0,1,1163,655.92h-3.43c-2.74-3.82-12.25-14.7-28.32-14.8-19.21,0-34.69,14.8-34.69,34.89s15.48,34.89,34.69,34.89c17,0,26.27-11.57,28.32-14.41Z"
        transform="translate(-594.39 -575.51)"
        fill="#fff"
      />
      <path
        d="M1221.79,712.27H1219c0-3.53,0-7,.1-11.27-3.53,9-12.54,12.74-21.76,12.54-15.49-.39-23.23-9.7-23.23-25.29V660.33h2.74v27.93c0,14,6.86,22.93,21.07,22.74,12.35-.2,21.07-7.15,21.07-20.78V660.33h2.74Z"
        transform="translate(-594.39 -575.51)"
        fill="#fff"
      />
      <path
        d="M1235.31,686.59c.2,17,14.41,24.5,24.6,24.4,9.6,0,18-4.9,22.15-13.72h3a26.76,26.76,0,0,1-10.49,12.35,28.59,28.59,0,0,1-14.8,3.92c-19.6,0-27.34-15.58-27.34-27.15,0-15.58,12-27.34,27.44-27.34a27.75,27.75,0,0,1,18.62,7,26,26,0,0,1,8.53,20.58Zm49-2.55c-.59-12.54-12.25-22.93-24.4-22.44-12.84,0-23.72,9.31-24.5,22.44Z"
        transform="translate(-594.39 -575.51)"
        fill="#fff"
      />
    </svg>
    // <svg
    //   className="logo"
    //   xmlns="http://www.w3.org/2000/svg"
    //   viewBox="0 0 177.8 215.34"
    // >
    //   <path
    //     d="M771,618.81a8,8,0,0,0-12-6.91l-91.16,52.15a19.37,19.37,0,0,0-9.69,16.78l1.14,102a8,8,0,0,0,12,6.91l94.58-54.13a12.53,12.53,0,0,0,6.27-10.86Zm-94.92,64.25a6.58,6.58,0,0,1,3.28-5.68l45.47-26.33a3.64,3.64,0,0,1,5.46,3.15v6.06a5.79,5.79,0,0,1-2.89,5l-45.86,26.56a3.64,3.64,0,0,1-5.46-3.15Zm59.35-12.93a3.64,3.64,0,0,1,5.47,3.15v6.06a5.79,5.79,0,0,1-2.89,5L709.28,701.3a3.64,3.64,0,0,1-5.46-3.15v-5.61a6.57,6.57,0,0,1,3.28-5.68ZM697.25,697v6.06a5.79,5.79,0,0,1-2.89,5l-12.76,7.33a3.64,3.64,0,0,1-5.46-3.15v-5.61a6.57,6.57,0,0,1,3.28-5.68l12.37-7.11a3.64,3.64,0,0,1,5.46,3.16Zm-21.13,36a5.86,5.86,0,0,1,2.92-5.06l3.51-2a4.16,4.16,0,0,1,6.25,3.61v5.08a4.14,4.14,0,0,1-2,3.54l-4.35,2.68a4.16,4.16,0,0,1-6.35-3.54Zm76.64-8.59a5.79,5.79,0,0,1-2.89,5l-68.29,39.37h0a3.64,3.64,0,0,1-5.46-3.16V760a6.58,6.58,0,0,1,3.28-5.68l67.9-39.14a3.64,3.64,0,0,1,5.46,3.15Zm2.06-27a5.79,5.79,0,0,1-2.89,5L698.6,733.28a3.64,3.64,0,0,1-5.46-3.15v-5.61a6.57,6.57,0,0,1,3.28-5.68l52.95-30.6a3.64,3.64,0,0,1,5.46,3.15Zm1.27-52a5.79,5.79,0,0,1-2.89,5l-12.76,7.33a3.64,3.64,0,0,1-5.46-3.15V649a6.58,6.58,0,0,1,3.28-5.68l12.37-7.11a3.64,3.64,0,0,1,5.46,3.16Z"
    //     transform="translate(-594.39 -575.51)"
    //     fill="#fff"
    //   />
    //   <path
    //     d="M658.32,660.12l66.4-38-.21-19.33a7,7,0,0,0-10.5-6.06l-80,45.77a17,17,0,0,0-8.5,14.72l1,89.53a7,7,0,0,0,10.5,6.06L650.61,745l-.78-70.2A17,17,0,0,1,658.32,660.12Z"
    //     transform="translate(-594.39 -575.51)"
    //     fill="#fff"
    //     opacity="0.7"
    //   />
    //   <path
    //     d="M626.49,638.45l65-37.17-.2-18.91A6.85,6.85,0,0,0,681,576.44l-78.28,44.78a16.63,16.63,0,0,0-8.32,14.41l1,87.61a6.85,6.85,0,0,0,10.27,5.93l13.3-7.61-.77-68.7A16.63,16.63,0,0,1,626.49,638.45Z"
    //     transform="translate(-594.39 -575.51)"
    //     fill="#fff"
    //     opacity="0.5"
    //   />
    // </svg>
  )
}

Logo.propTypes = {}

export default Logo
