import addToMailchimp from "gatsby-plugin-mailchimp"
import React, { useRef, useState } from "react"
import "./newsletter.sass"

const Newsletter = () => {
  const inputEl = useRef(null)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState("")
  const handleSubmit = evt => {
    const email = inputEl.current.value
    addToMailchimp(email)
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "success") {
          setSuccess(true)
          setError("")
        } else {
          setError(data.msg)
          setSuccess(false)
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        //
      })
  }
  return (
    <div className="newsletter">
      <div className="wrapper">
        <h3>We are still in development</h3>
        <p>
          Subscribe to get updates on our progress. No spam. Unsubscribe at
          anytime.
        </p>
        <div className="newsletter-form">
          <input
            type="email"
            ref={inputEl}
            placeholder="Email address"
            className="newsletter-email-input"
          />
          <input
            type="submit"
            onClick={handleSubmit}
            className="newsletter-email-submit"
            value="Subscribe"
          />
          {success && (
            <p className="newsletter-email-submit-message">
              Got it! Please check your inbox to confirm your subscription.
            </p>
          )}
          {Boolean(error) && (
            <p
              className="newsletter-email-submit-message"
              dangerouslySetInnerHTML={{ __html: error }}
            ></p>
          )}
        </div>
      </div>
    </div>
  )
}

Newsletter.propTypes = {}

export default Newsletter
