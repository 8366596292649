/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Footer from "./footer/footer"
import Header from "./header/header"
import How from "./how/how"
import "./layout.sass"
import Newsletter from "./newsletter/newsletter"
import Try from "./try/try"
import Why from "./why/why"
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className="wrapper">
        <main className="main">
          <Why />
        </main>
      </div>
      <Try />
      <How />
      <Newsletter />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
