import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Button from "@material-ui/core/Button"
import React from "react"
import Logo from "../logo/logo"
import "./header.sass"
import BetaRegModal from "../beta-reg-modal/beta-reg-modal"
const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        headingIllustration: file(
          relativePath: { eq: "landing-document-search-illustration.png" }
        ) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 1000, maxHeight: 769) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const onCTAClick = () => {
    const el = document.getElementById("try-it")
    el.scrollIntoView({ behavior: "smooth", inline: "nearest" })
  }

  return (
    <header>
      <div className="headerBackgroundWrapper">
        <div className="headerBackground">
          <svg
            id="elipse-svg"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 2736 1236"
          >
            <radialGradient id="blueGradient" x1="0" x2="0" y1="0" y2="1">
              <stop offset="20%" stop-color="#0067e0" />
              <stop offset="100%" stop-color="#02309c" />
            </radialGradient>
            <path
              d="M1368,0c755.53,0,1368,276.69,1368,618s-612.47,618-1368,618S0,959.31,0,618,612.47,0,1368,0Z"
              fill="url(#blueGradient)"
            />
          </svg>
        </div>
      </div>
      <div className="headerTopSection wrapper">
        <h1>
          <Logo className="logo" />
        </h1>
      </div>
      <div className="document-search-illustration">
        <Img fluid={data.headingIllustration.childImageSharp.fluid} />
      </div>
      <div className="wrapper">
        <div className="header-title">
          <h1>JavaScript Code Search Upgraded</h1>
          <p className="header-sub-title">
            Semantic Search Engine for JavaScript Code Bases.
          </p>
          <div className="header-actions">
            <BetaRegModal />
            <Button onClick={onCTAClick} className="try-cta" variant="outlined">
              See it in Action
            </Button>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
